<template>
  <div class="client">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-header form-title">{{ titlePage }}</div>
            <div class="card-body">
              <div class="container form-container">
                <b-tabs content-class="mt-3">
                  <b-tab title="Dados pessoais" active>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label>CPF</label>
                          <span class="required-field">*</span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="client.cpf"
                            v-on:change="validateCpf()"
                            v-mask="'###.###.###-##'"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Unidade Consumidora</label>
                          <span class="required-field">*</span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="client.consumer_unit"
                            v-on:change="verifyUniqueConsumerUnit()"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>Nome</label>
                          <span class="required-field">*</span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="client.name"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>Data de nascimento</label>
                          <span class="required-field">*</span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="client.birthdate"
                            v-mask="'##/##/####'"
                            v-on:change="checkDate()"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label>
                            Valor da Doação
                            <span class="required-field">*</span>
                          </label>
                          <money
                            :disabled="!adminLogged()"
                            class="form-control"
                            v-model="client.donation_value"
                            v-bind="money"
                          ></money>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label>
                            Valor Repasse
                            <span class="required-field">*</span>
                          </label>
                          <money
                            :disabled="!adminLogged()"
                            class="form-control"
                            v-model="client.donation_transfer"
                            v-bind="money"
                          ></money>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label>
                            Valor Seguro
                            <span class="required-field">*</span>
                          </label>
                          <money
                            :disabled="!adminLogged()"
                            class="form-control"
                            v-model="client.donation_safety"
                            v-bind="money"
                            @blur.native="checkDonation()"
                          ></money>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>Carteira</label>
                          <span class="required-field">*</span>
                          <select
                            v-model="client.wallet_id"
                            class="form-control"
                          >
                            <option
                              v-for="item in createParams.wallets"
                              :value="item.id"
                              v-bind:key="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-12">
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-end
                            toggle-input
                          "
                        >
                          <label for>Ativo para doação</label>
                          <toggle-button
                            v-model="client.donation_status"
                            v-bind:key="client.donation_status"
                            :disabled="!adminLogged()"
                          />
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Endereço">
                    <div class="col-lg- 12 col-12">
                      <address-item-component
                        :address="client.address"
                      ></address-item-component>
                      <div class="add-phone d-flex align-items-center">
                        <input
                          type="text"
                          class="form-control"
                          v-mask="['(##) ####-####', '(##) #####-####']"
                          v-model="newPhone"
                          placeholder="Novo telefone"
                        />
                        <button
                          class="btn btn-success"
                          v-bind:disabled="newPhone.length < 14"
                          v-on:click="addPhone()"
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </div>
                      <b-table
                        hover
                        :fields="fieldsPhones"
                        :items="client.phones"
                        sticky-header
                      >
                        <template v-slot:cell(id)="data">
                          <button
                            class="btn btn-danger"
                            v-on:click="removePhone(data)"
                          >
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </button>
                        </template>
                      </b-table>
                      <div
                        class="no-data"
                        v-if="!client.phones || client.phones.length == 0"
                      >
                        <span>Sem dados</span>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Dependentes">
                    <div class="add-dependent">
                      <div class="name">
                        <div class="form-group">
                          <label> Nome</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="newDependent.name"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label> Posição no Seguro</label>
                        <select
                          v-model="newDependent.client_position"
                          class="form-control"
                        >
                          <option
                            v-for="item in createParams.positions"
                            :value="item.value"
                            v-bind:key="item.value"
                          >
                            {{ item.label }}
                          </option>
                        </select>
                      </div>
                      <div class="second-line d-flex">
                        <input
                          type="text"
                          class="form-control"
                          v-mask="'##/##/####'"
                          v-on:change="checkDateDependent()"
                          v-model="newDependent.birthdate"
                          placeholder="Data de nascimento"
                        />
                        <input
                          type="text"
                          class="form-control"
                          v-model="newDependent.client_relationship"
                          placeholder="Parentesco"
                        />
                        <input
                          type="text"
                          class="form-control"
                          v-mask="'###.###.###-##'"
                          v-model="newDependent.cpf"
                          placeholder="CPF"
                        />
                        <button
                          class="btn btn-success"
                          v-on:click="addDependent()"
                          :disabled="!canAddDependent()"
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                    <b-table
                      hover
                      :fields="fieldsDependents"
                      :items="client.dependents"
                      sticky-header
                    >
                      <template v-slot:cell(client_position)="data">
                        {{ formatPosition(data.value) }}
                      </template>
                      <template v-slot:cell(birthdate)="data">{{
                        data.item.id ? formatDate(data.value) : data.value
                      }}</template>
                      <template v-slot:cell(id)="data">
                        <button
                          class="btn btn-danger"
                          v-on:click="removeDependent(data)"
                        >
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                      </template>
                    </b-table>
                    <div
                      class="no-data"
                      v-if="!client.dependents || client.dependents.length == 0"
                    >
                      <span>Sem dados</span>
                    </div>
                  </b-tab>
                  <b-tab title="Atendimentos">
                    <div class="container">
                      <div class="row">
                        <div class="col-9">
                          <div class="form-group">
                            <label v-if="formType == 'create'">
                              Gravação de Autorização
                            </label>
                            <label v-if="formType == 'edit'"
                              >Gravação de Autorização</label
                            >
                            <b-form-file
                              ref="fileinput"
                              placeholder="Escolha um arquivo"
                              drop-placeholder="Arraste o arquivo aqui..."
                              browse-text="Procurar"
                              v-on:change="uploadRecordFile"
                            ></b-form-file>
                            <label
                              class="warning"
                              v-if="
                                formType == 'edit' &&
                                  client.file_url != '' &&
                                  client.file_url != null
                              "
                            >
                              (alterando a Gravação de Autorização, o arquivo
                              atual será substituído pelo novo)
                            </label>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="openFile" v-if="formType == 'edit'">
                            <a
                              :href="client.file_url"
                              target="_blank"
                              rel="noopener noreferrer"
                              v-if="
                                formType == 'edit' &&
                                  client.file_url != '' &&
                                  client.file_url != null
                              "
                            >
                              <button class="btn btn-secondary float-right">
                                Abrir áudio
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                      <b-table
                        hover
                        :fields="fieldsHistoryClass"
                        :items="historyCalls"
                        sticky-header
                        @row-clicked="openQuestion"
                      >
                        <template v-slot:cell(created_at)="data">{{
                          data.item.id ? formatDateTime(data.value) : data.value
                        }}</template>
                        <template v-slot:cell(file_url)="data">
                          <a
                            :href="data.value"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button class="btn btn-secondary float-right">
                              Abrir áudio
                            </button>
                          </a>
                        </template>
                      </b-table>
                    </div>
                  </b-tab>
                  <b-tab title="Registo de Operações">
                    <div class="container">
                      <b-table
                        hover
                        :fields="fieldsHistoryOperations"
                        :items="historyOperations"
                        sticky-header
                        @row-clicked="openOperation"
                      >
                        <template v-slot:cell(created_at)="data">{{
                          data.item.id ? formatDateTime(data.value) : data.value
                        }}</template>

                        <template v-slot:cell(type)="data">
                          {{ formatType(data.value) }}
                        </template>

                        <template v-slot:cell(description)="data">
                          {{ formatDescription(data.value) }}
                        </template>

                        <template v-slot:cell(file_url)="data">
                          <div
                            class="justify-content-center"
                            v-if="data.value != null && data.value != ''"
                          >
                            <a
                              :href="data.value"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button class="btn btn-secondary float-right">
                                Abrir áudio
                              </button>
                            </a>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </b-tab>

                  <b-tab title="Histórico de Pagamento">
                    <div class="container">
                      <b-table
                        hover
                        :fields="fieldsHistoryPayment"
                        :items="historyPayment"
                        sticky-header
                      >
                        <template v-slot:cell(parcela)="data">
                          {{ formatParcela(data.value) }}
                        </template>

                        <template v-slot:cell(date_fatura)="data">
                          {{
                            formatDateOnly(data.value) == "12/1969"
                              ? "-"
                              : formatDateOnly(data.value)
                          }}
                        </template>

                        <template v-slot:cell(payment_date)="data">
                          {{
                            formatDate(data.value) == "31/12/1969"
                              ? "-"
                              : formatDate(data.value)
                          }}
                        </template>

                        <!-- <template v-slot:cell(client)="data">
                          {{ formatMoney(data.value) }}
                        </template> -->

                        <template
                          v-slot:cell(client)="data"
                          :data-reliable="{getIsDonationValueReliable(data)}"
                        >
                          {{ formatMoneyGeneric(getValidDonationValue(data)) }}
                        </template>

                        <template v-slot:cell(code)="data">
                          {{ formatCode(data.value) }}
                        </template>
                      </b-table>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
              <div class="container form-container">
                <div class="save-content">
                  <button
                    class="btn btn-primary float-right btn-submit"
                    v-on:click="save()"
                  >
                    Salvar
                  </button>
                  <button
                    v-if="formType == 'edit'"
                    class="btn btn-primary float-right btn-destroy"
                    v-on:click="destroy()"
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "@/api/axios";
import { bus } from "@/main";
import helper from "@/imports/Helpers";
import { Money } from "v-money";
import validator from "@/imports/validator/validate";
import client_validate from "@/imports/validator/models/client";
import AddressItemComponent from "@/components/AddressItemComponent";

export default {
  name: "client",
  props: {
    id: Number
  },
  components: {
    Money,
    AddressItemComponent
  },
  data() {
    return {
      client: {
        address: {},
        phones: [],
        dependents: []
      },
      historyOperations: [],
      historyPayment: [],
      createParams: {
        wallets: [],
        positions: []
      },
      formType: "create",
      titlePage: "Novo Cliente",
      currentPagePhones: 1,
      newPhone: "",
      newDependent: {},
      historyCalls: [],
      fieldsHistoryClass: [
        { key: "created_at", label: "Data", class: "date-cell" },
        { key: "user.name", label: "Operador", class: "user-cell" },
        { key: "description", label: "Descrição", class: "description-cell" },
        { key: "file_url", label: "Áudio", class: "audio-cell" }
      ],

      fieldsHistoryOperations: [
        { key: "created_at", label: "Data", class: "date-cell" },
        { key: "type", label: "Tipo", class: "type-cell" },
        { key: "user.name", label: "Operador", class: "user-cell" },
        { key: "description", label: "Descrição", class: "description-cell" },
        { key: "file_url", label: "Áudio", class: "audio-cell" }
      ],

      fieldsPhones: [
        { key: "number", label: "Número", class: "name-cell" },
        { key: "id", label: "Remover", class: "remove-cell" }
      ],
      fieldsDependents: [
        { key: "name", label: "Nome", class: "name-dependent-cell" },
        {
          key: "birthdate",
          label: "Data de nascimento",
          class: "birthdate-cell"
        },
        {
          key: "cpf",
          label: "CPF",
          class: "cpf-cell"
        },
        {
          key: "client_position",
          label: "Posição no Seguro",
          class: "client_position-cell"
        },
        {
          key: "client_relationship",
          label: "Parentesco",
          class: "relationship-cell"
        },
        { key: "id", label: "Remover", class: "remove-cell" }
      ],
      fieldsHistoryPayment: [
        {
          key: "parcela",
          label: "Parcela",
          class: "parcela-cell"
        },
        {
          key: "date_fatura",
          label: "Fatura",
          class: "fatura-cell"
        },
        { key: "client", label: "Valor", class: "valor-cell" },
        {
          key: "payment_date",
          label: "Data de Pagamento",
          class: "pagemento-cell"
        },
        {
          key: "code",
          label: "Status",
          class: "status-cell"
        }
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      navigate_items: [
        {
          text: "Clientes",
          route: "dashboard.clients"
        },
        {
          text: "Criar",
          active: true
        }
      ]
    };
  },
  mounted() {
    this.getCreateParams();
    this.client.donation_status = true;
    if (this.id) {
      this.formType = "edit";
      this.navigate_items[1].text = "Editar";
      this.getDataObject(this.id);
      this.getCallsHistory(this.id);
    }
  },
  methods: {
    getDataObject: function() {
      bus.$emit("changeLoading", true);
      helper.getDataObject("clients", this.id).then(response => {
        this.client = response.data;
        this.client.wallet_id = this.client.wallets[0].id;
        this.client.birthdate = this.formatDate(this.client.birthdate);
        this.historyOperations = this.client.clients_operations;
        //this.historyPayment = this.client.shipment_return_clients;

        this.historyPayment = this.client.shipment_return_clients.filter(
          function(a) {
            return (
              !this[JSON.stringify(a.date_fatura)] &&
              (this[JSON.stringify(a.date_fatura)] = true)
            );
          },
          Object.create(null)
        );

        this.titlePage = this.client.name;
        this.checkDependents();
        bus.$emit("changeLoading", false);
      });
    },
    getCreateParams: function() {
      bus.$emit("changeLoading", true);
      helper.getCreateParams("clients").then(data => {
        this.createParams = data.data;
        bus.$emit("changeLoading", false);
      });
    },
    uploadRecordFile: function(e) {
      const file = e.target.files[0];
      if (!file.type.includes("audio/")) {
        this.$refs.fileinput.reset();
        Swal.fire({
          title: "Este arquivo não é suportado. Ele não será importado.",
          icon: "warning"
        });
        return;
      } else if (e.target.files[0].size / 1000000 > 50) {
        this.$refs.fileinput.reset();
        Swal.fire({
          title: "O tamanho máximo permitido é 50MB.",
          icon: "warning"
        });
        return;
      } else {
        this.client.record_file = file;
      }
    },
    checkDonation: function() {
      if (
        this.client.donation_safety != null &&
        this.client.donation_transfer != null &&
        this.client.donation_value != null
      ) {
        if (
          this.client.donation_safety + this.client.donation_transfer !=
          this.client.donation_value
        ) {
          this.client.donation_safety = "";
          this.client.donation_transfer = "";
          Swal.fire({
            title:
              "A soma dos campos Valor Repasse e Valor Seguro é maior que do Valor de Doação",
            icon: "warning"
          });
        }
      }
    },
    save: function() {
      const errors = validator.validate(this.client, client_validate.model);

      if (errors.length == 0) {
        const data = JSON.parse(JSON.stringify(this.client));
        data.birthdate = helper.formatBRDateToUs(this.client.birthdate);
        data.dependents.forEach(el => {
          el.birthdate = helper.formatBRDateToUs(el.birthdate);
        });
        data.dependents = JSON.stringify(data.dependents);
        data.phones = JSON.stringify(data.phones);
        if ((this.client.address.length === 0) == false) {
          data.address = JSON.stringify(this.client.address);
        } else {
          data.address = [];
        }
        data.record_file = this.client.record_file;
        helper
          .saveFormDataObject("clients", data, this.formType, "Cliente", "o")
          .then(() => {
            this.$router.push({ name: "dashboard.clients" });
          });
      } else {
        helper.showErrorsForm(errors);
      }
    },
    openQuestion: function(record, index) {
      this.$router.push({
        name: "dashboard.call",
        params: { id: record.id }
      });
    },
    openOperation: function(record, index) {
      if (record.type == "update") {
        this.$router.push({
          name: "dashboard.update-donation",
          params: { id: record.id, type: "edit" }
        });
      } else {
        this.$router.push({
          name: "dashboard.cancel-donation",
          params: { id: record.id, type: "edit" }
        });
      }
      //
    },
    getCallsHistory: function(client_id) {
      axios
        .post(`${process.env.VUE_APP_API_HOST}api/get_calls_history`, {
          client_id: client_id
        })
        .then(response => {
          this.historyCalls = response.data;
        });
    },
    destroy: function() {
      helper.destroyDataObject("clients", this.client).then(() => {
        this.$router.push({ name: "dashboard.clients" });
      });
    },
    verifyUniqueConsumerUnit: function() {
      helper
        .verifyUniqueCred(
          "uniqueConsumerUnit",
          this.client.consumer_unit,
          "Unidade Consumidora"
        )
        .then(response => {
          if (response.status == 200) {
            this.client.consumer_unit = "";
          }
        });
    },
    validateCpf: function() {
      const result = helper.validateCpf(this.client.cpf);
      if (result == false) {
        Swal.fire({
          title: `O campo CPF não esta no formato correto.`,
          icon: "info"
        });
        this.client.cpf = "";
      }
    },
    checkDate: function() {
      if (!helper.validateDateBR(this.client.birthdate)) {
        this.client.birthdate = "";
        Swal.fire({
          title: "Insira uma data válida",
          icon: "warning"
        });
      }
    },
    checkDateDependent: function() {
      if (!helper.validateDateBR(this.newDependent.birthdate)) {
        this.newDependent.birthdate = "";
        Swal.fire({
          title: "Insira uma data válida",
          icon: "warning"
        });
      }
    },
    canAddDependent() {
      return (
        this.newDependent.name &&
        this.newDependent.birthdate &&
        this.newDependent.client_position &&
        this.newDependent.client_relationship
      );
    },
    addDependent() {
      if (this.newDependent.client_position == "holder") {
        this.createParams.positions = this.createParams.positions.splice(1, 1);
      }
      this.client.dependents.push(this.newDependent);
      this.newDependent = {};
    },
    removeDependent(dependent) {
      if (dependent.item.client_position == "holder") {
        this.createParams.positions.splice(0, 0, {
          label: "Titular",
          value: "holder"
        });
      }
      if (dependent.item.id) {
        const index = this.client.dependents.findIndex(el => {
          return el.id == dependent.item.id;
        });
        this.client.dependents.splice(index, 1);
      } else {
        this.client.dependents.splice(dependent.index, 1);
      }
    },
    adminLogged() {
      return helper.isAdminLogged();
    },
    addPhone() {
      this.client.phones.push({
        number: this.newPhone
      });
      this.newPhone = "";
    },
    removePhone(phone) {
      if (phone.item.id) {
        const index = this.client.phones.findIndex(el => {
          return el.id == phone.item.id;
        });
        this.client.phones.splice(index, 1);
      } else {
        this.client.phones.splice(phone.index, 1);
      }
    },
    checkDependents: function() {
      for (const dependent of this.client.dependents) {
        if (dependent.client_position == "holder") {
          this.createParams.positions = this.createParams.positions.splice(
            1,
            1
          );
        }
      }
    },
    formatDate: function(date) {
      return helper.formatDate(date);
    },
    formatDateTime: function(date) {
      return helper.formatDateTime(date);
    },
    formatDateOnly: function(date) {
      return helper.formatDateOnly(date);
    },
    formatDateFatura: function(date) {
      return helper.formatDate(date);
    },
    formatMoney: function(date) {
      return helper.formatMoney(date.donation_value);
    },
    formatMoneyGeneric: function(value) {
      return helper.formatMoney(value);
    },
    formatType: function(type) {
      return type == "update"
        ? "Atualização"
        : type == "activate"
        ? "Ativado"
        : "Cancelamento";
    },
    formatCode: function(code) {
      let value = "";
      switch (code) {
        case "99":
          value = "Registro recebido";
          break;

        case "89":
          value = "Cobrança só faturada";
          break;

        case "02":
          value = "Parcela cancelada";
          break;

        case "00":
          value = "Cobrança arrecadada";
          break;

        default:
          value = "Não identificado";
          break;
      }
      return value;
    },
    formatParcela: function(code) {
      let value = "";
      if (value != "null") {
        value = `${code}/36`;
      }
      return value;
    },
    formatPosition: function(position) {
      return position == "holder" ? "Titular" : "Dependente";
    },
    formatDescription: function(description) {
      return description == "" ? "Sem informação" : description;
    },
    getIsDonationValueReliable(data) {
      let isReliable = false;

      if (data.item.donation_value != null) {
        isReliable = true;
      } else if (
        data.item.donation_value_at_current_history_item &&
        data.item.donation_value_at_current_history_item.value
      ) {
        isReliable =
          data.item.donation_value_at_current_history_item.isReliable;
      }

      return isReliable;
    },
    getValidDonationValue(data) {
      let value = data.value;

      if (data.item.donation_value != null) {
        value = data.item.donation_value;
      } else if (
        data.item.donation_value_at_current_history_item &&
        data.item.donation_value_at_current_history_item.value
      ) {
        value = data.item.donation_value_at_current_history_item.value;
      }

      return value || 0;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/general.scss";

.client {
  .card {
    min-height: 650px;
  }
  .openFile {
    padding-top: 32px;
    padding-left: 0px;
  }
  .warning {
    font-size: 12px;
    color: red;
  }
  .card-body {
    position: relative;
  }
  .save-content {
    // position: absolute;
    bottom: 25px;
    right: calc(8vw + 15px);
  }
  .add-phone {
    margin-bottom: 26px;
    button {
      margin-left: 14px;
    }
  }
  .add-dependent {
    margin-bottom: 26px;
    .second-line {
      input {
        margin-right: 14px;
      }
    }
  }
  .date-cell {
    width: 25%;
  }
  .user-cell {
    width: 25%;
  }
  .description-cell {
    width: 30%;
  }
  .audio-cell {
    width: 20%;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
  }
  .cpf-cell {
    width: 90%;
  }
  .name-cell {
    width: 90%;
  }
  .name-dependent-cell {
    width: 50%;
  }
  .relationship-cell {
    width: 25%;
  }
  .birthdate-cell {
    width: 25%;
  }
  .remove-cell {
    width: 10%;
  }

  .fatura-cell {
    width: 10%;
  }
  .fatura-cell {
    width: 20%;
  }
  .valor-cell {
    width: 20%;
  }
  .pagamento-cell {
    width: 25%;
  }
  .status-cell {
    width: 25%;
  }
}
</style>
