<template>
  <div class="address-component">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 col-12">
          <div class="form-group">
            <label>CEP</label>
            <input
              type="text"
              class="form-control"
              v-mask="'#####-###'"
              v-model="address.postal_code"
              v-on:change="getAddresByCep()"
            />
          </div>
        </div>
        <div class="col-lg-9 col-12">
          <div class="form-group">
            <label>Rua</label>
            <input type="text" class="form-control" v-model="address.street" />
          </div>
        </div>
        <div class="col-lg-9 col-12">
          <div class="form-group">
            <label>Bairro</label>
            <input type="text" class="form-control" v-model="address.neighborhood" />
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="form-group">
            <label>Número</label>
            <input type="text" class="form-control" v-model="address.number" />
          </div>
        </div>
        <div class="col-lg-9 col-12">
          <div class="form-group">
            <label>Cidade</label>
            <input type="text" class="form-control" v-model="address.city" />
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="form-group">
            <label>Estado</label>
            <select class="form-control" v-model="address.state">
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
              <option value="EX">Estrangeiro</option>
            </select>
          </div>
        </div>
        <div class="col-lg-12 col-12">
          <div class="form-group">
            <label>Complemento</label>
            <input type="text" class="form-control" v-model="address.complement" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: "address-item",
  props: {
    address: Object
  },
  methods: {
    getAddresByCep: function() {
      const that = this;
      let tipCep = this.address.postal_code.replace("-", "");
      axios
        .get("https://viacep.com.br/ws/" + tipCep + "/json/")
        .then(({ data }) => {
          that.updateAddress(data);
        })
        .catch(function() {});
    },
    updateAddress: function(data) {
      this.$set(this.address, "street", data.logradouro);
      this.$set(this.address, "neighborhood", data.bairro);
      this.$set(this.address, "city", data.localidade);
      this.$set(this.address, "state", data.uf);
    }
  }
};
</script>

<style lang="scss">
.address-component {
  .container-fluid {
    padding: 0;
  }
}
</style>