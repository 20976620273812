export default {
  model: [
    {
      label: 'Nome',
      field: 'name',
      type: 'text'
    },
    {
      label: 'Data de nascimento',
      field: 'birthdate',
      type: 'text'
    },
    {
      label: 'CPF',
      field: 'cpf',
      type: 'cpf',
      sanitizer: true,
      min: 11
    },
    {
      label: 'Unidade consumidora',
      field: 'consumer_unit',
      type: 'text'
    },
    {
      label: 'Valor da Doação',
      field: 'donation_value',
      type: 'text'
    },
    {
      label: 'Valor Repasse',
      field: 'donation_transfer',
      type: 'text'
    },
    {
      label: 'Carteira',
      field: 'wallet_id',
      type: 'text'
    },
  ]
}